.chart-header{
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  max-height: 75px;
}

.chart-main{
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  row-gap: 5px;
}

.chart-main_single{
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

.chart-content{
  position: relative;
  margin: auto;
  height: 45vh;
  max-height: 300px;
  width: 40vw;
}

.chart-content_wide{
  position: relative;
  margin: auto;
  height: 50vh;
  max-height: 350px;
  width: 75vw;
}

.chart-content_single{
  position: relative;
  margin: auto;
  height: 50vh;
  max-height: 300px;
  width: 75vw;
}


/* Для экранов шириной <= 1024px */
 @media screen and (max-width: 1024px) {
  .chart-content_single, .chart-content, .chart-content_wide {
    position: relative;
    margin: auto;
    height: 70vh;
    max-height: 250px;
    width: 80vw;
  }
}

.chart-comment{
  flex: 1 1 auto;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  row-gap: 5px;
  column-gap: 10px; 
  padding: 15px 0;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .chart-comment{
    padding: 15px 0 0 0;
  }
}

.popper{
  z-index: 1000 !important;
}
