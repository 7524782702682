.wrapper{
  display: grid;
  height: 100%;
  width: 100%;
  grid-template: minmax(75px, auto) 1fr minmax(44px, auto) / 5% 1fr 5%;
}

.header{
  grid-column: 2 / 3;
}

.main{
  display: grid;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  margin-bottom: 5%;
}

.footer{
  grid-column: 2 / 3;
}

@media screen and (max-width: 1024px) {
  .wrapper{
    background-color: #FFFFFF;
  }
}
