/*Обнуление*/
*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: 'Roboto',  'Helvetica', sans-serif;
  font-size: 16px;
  line-height: 24px;
}

::-webkit-scrollbar { width: 5px; height: 5px;}
::-webkit-scrollbar-track {  background-color: #999;}
::-webkit-scrollbar-track-piece { background-color: #f5f5f5;}
::-webkit-scrollbar-thumb { height: 50px; background-color: #a8a8a8; border-radius: 20px;}
::-webkit-scrollbar-corner { background-color: #999;}
::-webkit-resizer { background-color: #666;}