.form-wrapper{
  display: grid;
  height: 100%;
  width: 100%;
  grid-gap: 45px;
  grid-template-areas:
    "form-banner" 
    "form-main";
}

.form-wrapper_small{
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-areas:
    "form-main";
}

.form-banner{
  grid-area: form-banner;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-banner img{
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
  position: absolute;
  top:0;
  left:0;
}

.form-banner-text{
  padding: 7% 0;
}

.banner-text{
  position: relative;
  left: 5%;
  max-width: 60%;
}

.banner-text__title{
  font-size: 40px !important;
}

.banner-text__content{
  font-size: 20px !important;
  padding: 20px 0;
}

@media screen and (min-width: 1440px) {
  .banner-text__content{
    max-width: 45%;
  }
}

@media screen and (min-width: 1024px)and (max-width: 1440px) {
  .banner-text__content{
    max-width: 55%;
  }
}

.form-main{
  grid-area: form-main;
  display: grid;
  grid-gap: 30px;
  position: relative;
}

.single-charts-wrapper{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .single-charts-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
  }
}
