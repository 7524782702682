@media screen and (max-width: 576px) {
  .tools-wrapper{
    column-gap: 10px !important;
  }

  .field_autocomplite{
    min-width: 100px !important;
    width: 100% !important;
  }

  .field_date{
    min-width: 150px !important;
    max-width: 40% !important;
  }
}