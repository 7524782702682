.footer-text{
  text-align: start;
  font-size: 16px;
  color: #AAAAAA;
  font-family: 'Helvetica';
}

@media screen and (max-width: 1024px) {
  .footer-text{
    text-align: center;
  }
}